/*.action-cell {*/
/*  align-items: flex-start;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  height: 484px;*/
/*  position: relative;*/
/*  width: 457px;*/
/*}*/

/*.action-cell .image-wrapper-2 {*/
/*  align-self: stretch;*/
/*  flex: 1;*/
/*  flex-grow: 1;*/
/*  position: relative;*/
/*  width: 100%;*/
/*}*/

/*.action-cell .textwrapper {*/
/*  align-items: flex-start;*/
/*  align-self: stretch;*/
/*  background-color: #ffffff;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  gap: 2px;*/
/*  height: 171px;*/
/*  padding: 15px 10px;*/
/*  position: relative;*/
/*  width: 100%;*/
/*}*/

/*.action-cell .title-4 {*/
/*  align-self: stretch;*/
/*  color: var(--themeswamp);*/
/*  font-family: var(--theme-inter-bold-21-font-family);*/
/*  font-size: var(--theme-inter-bold-21-font-size);*/
/*  font-style: var(--theme-inter-bold-21-font-style);*/
/*  font-weight: var(--theme-inter-bold-21-font-weight);*/
/*  letter-spacing: var(--theme-inter-bold-21-letter-spacing);*/
/*  line-height: var(--theme-inter-bold-21-line-height);*/
/*  margin-top: -1.00px;*/
/*  position: relative;*/
/*}*/

/*.action-cell .subtitle-4 {*/
/*  align-self: stretch;*/
/*  color: var(--themenevada);*/
/*  font-family: var(--theme-inter-regular-15-13-font-family);*/
/*  font-size: var(--theme-inter-regular-15-13-font-size);*/
/*  font-style: var(--theme-inter-regular-15-13-font-style);*/
/*  font-weight: var(--theme-inter-regular-15-13-font-weight);*/
/*  letter-spacing: var(--theme-inter-regular-15-13-letter-spacing);*/
/*  line-height: var(--theme-inter-regular-15-13-line-height);*/
/*  position: relative;*/
/*}*/

.action-cell {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /*height: 700px;*/
  position: relative;
  width: 100%;
  /*max-width: 50%;*/
}

.action-cell .iframe-wrapper {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.action-cell .youtube-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.action-cell .textwrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 15px 10px;
  position: relative;
  width: 100%;
}

.action-cell .title-4 {
  align-self: stretch;
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-21-font-family);
  font-size: var(--theme-inter-bold-21-font-size);
  font-style: var(--theme-inter-bold-21-font-style);
  font-weight: var(--theme-inter-bold-21-font-weight);
  letter-spacing: var(--theme-inter-bold-21-letter-spacing);
  line-height: var(--theme-inter-bold-21-line-height);
  margin-top: -1.00px;
  position: relative;
}

.action-cell .subtitle-4 {
  align-self: stretch;
  color: var(--themenevada);
  font-family: var(--theme-inter-regular-15-13-font-family);
  font-size: var(--theme-inter-regular-15-13-font-size);
  font-style: var(--theme-inter-regular-15-13-font-style);
  font-weight: var(--theme-inter-regular-15-13-font-weight);
  letter-spacing: var(--theme-inter-regular-15-13-letter-spacing);
  line-height: var(--theme-inter-regular-15-13-line-height);
  position: relative;
}

.youtube-iframe {
  width: 10%;
}

.thumbnail-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-button-overlay {
  font-size: 64px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 10px 20px;
}

.element-news-detail {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.element-news-detail .navigation-desktop-14 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-news-detail .news-detail {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-news-detail .news-detail-wrapper {
  align-items: center;
  background-color: var(--themewhite);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1140px;
  overflow-y: scroll;
  padding: 32px 0px;
  position: relative;
}

.element-news-detail .news-detail-content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element-news-detail .news-detail-content-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element-news-detail .news-detail-content-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  max-width: 782px;
  padding: 20px 32px;
  position: relative;
  width: 100%;
}

.element-news-detail .news-detail-content-4 {
  align-self: stretch;
  color: var(--themeguardsman-red);
  font-family: var(--theme-inter-regular-11-63-upper-font-family);
  font-size: 18px;
  font-style: var(--theme-inter-regular-11-63-upper-font-style);
  font-weight: var(--theme-inter-regular-11-63-upper-font-weight);
  letter-spacing: var(--theme-inter-regular-11-63-upper-letter-spacing);
  line-height: var(--theme-inter-regular-11-63-upper-line-height);
  margin-top: -1.00px;
  position: relative;
}

.element-news-detail .news-detail-content-5 {
  align-self: stretch;
  color: var(--themeswamp);
  font-family: var(--theme-inter-regular-40-font-family);
  font-size: var(--theme-inter-regular-40-font-size);
  font-style: var(--theme-inter-regular-40-font-style);
  font-weight: var(--theme-inter-regular-40-font-weight);
  letter-spacing: var(--theme-inter-regular-40-letter-spacing);
  line-height: var(--theme-inter-regular-40-line-height);
  position: relative;
}

.element-news-detail .news-detail-content-6 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: var(--theme-inter-regular-12-upper-font-family);
  font-size: var(--theme-inter-regular-12-upper-font-size);
  font-style: var(--theme-inter-regular-12-upper-font-style);
  font-weight: var(--theme-inter-regular-12-upper-font-weight);
  letter-spacing: var(--theme-inter-regular-12-upper-letter-spacing);
  line-height: var(--theme-inter-regular-12-upper-line-height);
  position: relative;
}

.element-news-detail .hero-img {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  max-width: 1400px;
  min-height: 640px;
  padding: 0px 0px 24px;
  position: relative;
  width: 100%;
}

.element-news-detail .image-12 {
  align-self: stretch;
  background-image: url(../../../static/img/image-6.png);
  background-position: 00% 50%;
  background-size: cover;
  height: 641px;
  position: relative;
  width: 100%;
  object-fit: contain;
}

.element-news-detail .news-detail-content-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  max-width: 782px;
  padding: 10px 32px 20px;
  position: relative;
  width: 100%;
}

.element-news-detail .news-detail-content-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.element-news-detail .news-detail-content-8 {
  align-self: stretch;
  color: var(--themeouter-space);
  font-family: "Inter", Helvetica;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  font-family: "Inter", Helvetica;
}

.element-news-detail .text-wrapper-89 {
  font-weight: 700;
}

.element-news-detail .text-wrapper-90 {
  color: #34383c;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
}

.element-news-detail .footer-13 {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-news-detail .footer-14 {
  margin-left: -324px !important;
}

.element-news-detail .content-frame-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-news-detail .news-detail-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 0px 0px;
  justify-content: center;
  position: relative;

}
.news-detail-content-8 {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

.news-detail-content-8 p {
  margin-bottom: 15px;
}

.news-detail-content-8 strong {
  font-weight: bold;
}

.news-detail-content-8 em {
  font-style: italic;
}

.news-detail-content-8 u {
  text-decoration: underline;
}

.news-detail-content-8 ul,
.news-detail-content-8 ol {
  padding-left: 20px;
  margin-bottom: 15px;
}

.news-detail-content-8 h1,
.news-detail-content-8 h2,
.news-detail-content-8 h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

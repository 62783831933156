.hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
}

.hero img.hero-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Optional: cover the container, can also use 'contain' */
}

.hero .content {
  padding: 20px;
  background-color: #000000; /* Optional background color */
  color: var(--themewhite);
  text-align: center;
}

.hero .subheader {
  font-family: "Inter", Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  line-height: 22.9px;
  margin-top: 0;
}

.hero .header-2 {
  font-family: var(--theme-inter-bold-27-75-font-family);
  font-size: var(--theme-inter-bold-27-75-font-size);
  font-weight: var(--theme-inter-bold-27-75-font-weight);
  line-height: var(--theme-inter-bold-27-75-line-height);
  margin-top: 10px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
}

.loading-text {
  font-size: 1.5rem;
  color: #555;
  font-weight: bold;
}

.team-detail-squad-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 0px 10px;
  position: relative;
}

.team-detail-squad-wrapper .team-detail-team {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.team-detail-squad-wrapper .team-detail-team-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  position: relative;
  width: 100%;
}

.team-detail-squad-wrapper .team-detail-team-2 {
  align-items: center;
  background-color: black;
  color: white;
  box-shadow: 0px 2px 8px #000e140d;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 5px 5px;
  padding: 8px 10px;
  position: relative;
  margin-right: 5px;
}

.team-detail-squad-wrapper .club-img {
  background-position: 50% 50%;
  background-size: cover;
  height: 50px;
  max-width: 80px;
  position: relative;
  width: 50px;
  background-color: white;
  padding: 3px;
  margin-right: 10px;
}

.team-detail-squad-wrapper .team-detail-team-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  position: relative;
}

.team-detail-squad-wrapper .team-detail-team-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.team-detail-squad-wrapper .team-detail-squad-2 {
  font-family: var(--theme-inter-bold-15-63-font-family);
  font-size: var(--theme-inter-bold-15-63-font-size);
  font-style: var(--theme-inter-bold-15-63-font-style);
  font-weight: var(--theme-inter-bold-15-63-font-weight);
  letter-spacing: var(--theme-inter-bold-15-63-letter-spacing);
  line-height: var(--theme-inter-bold-15-63-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.team-detail-squad-wrapper .team-detail-squad-3 {
  font-family: var(--theme-inter-regular-15-63-font-family);
  font-size: var(--theme-inter-regular-15-63-font-size);
  font-style: var(--theme-inter-regular-15-63-font-style);
  font-weight: var(--theme-inter-regular-15-63-font-weight);
  letter-spacing: var(--theme-inter-regular-15-63-letter-spacing);
  line-height: var(--theme-inter-regular-15-63-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

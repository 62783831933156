.view-default-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.view-default-wrapper .main-navigation-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--themewhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.view-default-wrapper .league-rows-2 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e9ebed;
  display: flex;
  flex-direction: column;
  height: 36px;
  justify-content: center;
  padding: 0px 0px 10px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .leauge-row-wrapper-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 15px;
  max-width: 1440px;
  padding: 5.08px 18px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .league-row-item-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  position: relative;
}

.view-default-wrapper .league-row-item-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 24px;
  justify-content: center;
  position: relative;
}

.view-default-wrapper .league-row-item-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: 50px;
}

.view-default-wrapper .content-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  gap: 5px;
}

.view-default-wrapper .league-row-item-8 {
  height: 13px;
  position: relative;
  width: 16px;
}

.view-default-wrapper .league-row-item-9 {
  /*color: var(--themeouter-space);*/
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.view-default-wrapper .league-row-item-10 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
  background-color: black;
}

.view-default-wrapper .league-row-item-11 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  position: relative;
}

.view-default-wrapper .league-row-item-12 {
  height: 13px;
  position: relative;
  width: 16px;
}

.view-default-wrapper .league-row-item-13 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.view-default-wrapper .league-row-item-14 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .league-row-item-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  position: relative;
}

.view-default-wrapper .league-row-item-16 {
  height: 13px;
  position: relative;
  width: 16px;
}

.view-default-wrapper .league-row-item-17 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.view-default-wrapper .league-row-item-18 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .league-row-item-19 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  position: relative;
}

.view-default-wrapper .league-row-item-20 {
  height: 13px;
  position: relative;
  width: 16px;
}

.view-default-wrapper .league-row-item-21 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.view-default-wrapper .league-row-item-22 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .league-row-item-23 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  position: relative;
}

.view-default-wrapper .league-row-item-24 {
  height: 13px;
  position: relative;
  width: 16px;
}

.view-default-wrapper .league-row-item-25 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.view-default-wrapper .league-row-item-26 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .league-row-item-27 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  position: relative;
}

.view-default-wrapper .league-row-item-28 {
  height: 13px;
  position: relative;
  width: 16px;
}

.view-default-wrapper .league-row-item-29 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.view-default-wrapper .league-row-item-30 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .league-row-item-31 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  position: relative;
}

.view-default-wrapper .league-row-item-32 {
  height: 13px;
  position: relative;
  width: 16px;
}

.view-default-wrapper .league-row-item-33 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.view-default-wrapper .league-row-item-34 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .league-row-item-35 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  position: relative;
}

.view-default-wrapper .league-row-item-36 {
  height: 13px;
  position: relative;
  width: 16px;
}

.view-default-wrapper .league-row-item-37 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.view-default-wrapper .league-row-item-38 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .league-row-item-39 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  position: relative;
}

.view-default-wrapper .league-row-item-40 {
  height: 13px;
  position: relative;
  width: 16px;
}

.view-default-wrapper .league-row-item-41 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.view-default-wrapper .league-row-item-42 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .nav-row-wrapper-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 15px 18px 10px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .nav-row-wrapper-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 30.92px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.view-default-wrapper .nav-row-wrapper-6 {
  height: 44.03px;
  margin-bottom: -6.56px;
  margin-top: -6.56px;
  object-fit: cover;
  position: relative;
  width: 120px;
}

.view-default-wrapper .nav-row-wrapper-7 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 56px;
  justify-content: center;
  margin-bottom: -12.54px;
  margin-top: -12.54px;
  position: relative;
}

.view-default-wrapper .item-3 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0px 0px 8px;
  position: relative;
}

.view-default-wrapper .link-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 16.15px 16px 16.99px 0px;
  position: relative;
  width: 100%;
}

.view-default-wrapper .text-wrapper-8 {
  color: var(--themeoslo-gray);
  font-family: var(--theme-inter-regular-13-25-font-family);
  font-size: var(--theme-inter-regular-13-25-font-size);
  font-style: var(--theme-inter-regular-13-25-font-style);
  font-weight: var(--theme-inter-regular-13-25-font-weight);
  letter-spacing: var(--theme-inter-regular-13-25-letter-spacing);
  line-height: var(--theme-inter-regular-13-25-line-height);
  margin-top: -1.07px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.view-default-wrapper .text-wrapper-9 {
  color: var(--themeoslo-gray);
  font-family: var(--theme-inter-regular-12-75-font-family);
  font-size: var(--theme-inter-regular-12-75-font-size);
  font-style: var(--theme-inter-regular-12-75-font-style);
  font-weight: var(--theme-inter-regular-12-75-font-weight);
  letter-spacing: var(--theme-inter-regular-12-75-letter-spacing);
  line-height: var(--theme-inter-regular-12-75-line-height);
  margin-top: -1.07px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.view-default-wrapper .link-7 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.view-default-wrapper .nav-row-wrapper-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 56px;
  justify-content: center;
  margin-bottom: -12.54px;
  margin-top: -12.54px;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  opacity: 0.5;
  position: relative;
}

.view-default-wrapper .nav-row-wrapper-11 {
  align-self: stretch;
  position: relative;
  width: 24px;
}

.view-default-wrapper .text-wrapper-10 {
  color: var(--themeoslo-gray);
  font-family: var(--theme-inter-regular-13-13-font-family);
  font-size: var(--theme-inter-regular-13-13-font-size);
  font-style: var(--theme-inter-regular-13-13-font-style);
  font-weight: var(--theme-inter-regular-13-13-font-weight);
  letter-spacing: var(--theme-inter-regular-13-13-letter-spacing);
  line-height: var(--theme-inter-regular-13-13-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.view-default-wrapper .mobile-burger-menu-2 {
  display: block;
  position: relative;
  width: 24px;
}

.view-default-wrapper .nav-row-wrapper-12 {
  align-items: center;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  margin-left: -280px;
  margin-right: -176.00px;
  position: relative;
}

.view-default-wrapper .mobile-burger-menu-3 {
  display: block;
  margin-left: -200px;
  position: relative;
  width: 24px;
}

.view-default-wrapper.default {
  width: 100%;
  background-color: white;
}

.view-default-wrapper.mobile {
  width: 390px;
}

.view-default-wrapper.mobile .league-row-item-27 {
  margin-right: -15.00px;
}

.view-default-wrapper.mobile .league-row-item-31 {
  margin-right: -79.00px;
}

.view-default-wrapper.mobile .league-row-item-32 {
  margin-bottom: -3926.42px;
  margin-right: -3987.00px;
}

.view-default-wrapper.mobile .league-row-item-33 {
  margin-left: -4016px;
}

.view-default-wrapper.mobile .league-row-item-34 {
  margin-bottom: -3928.42px;
  margin-right: -4020.00px;
}

.view-default-wrapper.mobile .league-row-item-35 {
  margin-right: -143.00px;
}

.view-default-wrapper.mobile .league-row-item-36 {
  margin-bottom: -3926.42px;
  margin-right: -3923.00px;
}

.view-default-wrapper.mobile .league-row-item-37 {
  margin-left: -3953px;
}

.view-default-wrapper.mobile .league-row-item-38 {
  margin-bottom: -3928.42px;
  margin-right: -3956.00px;
}

.view-default-wrapper.mobile .league-row-item-39 {
  margin-right: -207.00px;
}

.view-default-wrapper.mobile .league-row-item-40 {
  margin-bottom: -3926.42px;
  margin-right: -3859.00px;
}

.view-default-wrapper.mobile .league-row-item-41 {
  margin-left: -3888px;
}

.view-default-wrapper.mobile .league-row-item-42 {
  margin-bottom: -3928.42px;
  margin-right: -3892.00px;
}
.league-row-item-6.active {
  background-color: black; /* Active background */
  color: white; /* Active text color */
}

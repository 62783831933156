.team-detail-squad {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 4px;
  position: relative;
}

.team-detail-squad .team-detail-squad-wrapper-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.team-detail-squad .div-10 {
  align-items: center;
  align-self: stretch;
  background-color: var(--themewhite);
  box-shadow: 0px 2px 8px #000e140d;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  padding: 8px 16.01px 8px 16px;
  position: relative;
  width: 100%;
}

.team-detail-squad .image-11 {
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  object-fit: contain;
}


.team-detail-squad .team-detail-squad-wrapper-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 16px;
  position: relative;
  width: 46px;
}

.team-detail-squad .text-wrapper-21 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.team-detail-squad .team-detail-squad-wrapper-4 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  padding: 0px 0px 0px 10px;
  position: relative;
}

.team-detail-squad .div-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  flex-direction: column;
}

.team-detail-squad .text-wrapper-22 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-15-63-font-family);
  font-size: var(--theme-inter-regular-15-63-font-size);
  font-style: var(--theme-inter-regular-15-63-font-style);
  font-weight: bolder;
  letter-spacing: var(--theme-inter-regular-15-63-letter-spacing);
  line-height: var(--theme-inter-regular-15-63-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  max-width: 200px;
}

.team-detail-squad .text-wrapper-22 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-15-63-font-family);
  font-size: 14px;
  font-style: var(--theme-inter-regular-15-63-font-style);
  font-weight: var(--theme-inter-regular-15-63-font-weight);
  letter-spacing: var(--theme-inter-regular-15-63-letter-spacing);
  line-height: var(--theme-inter-regular-15-63-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.team-detail-squad .text-wrapper-23 {
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-15-63-font-family);
  font-size: var(--theme-inter-bold-15-63-font-size);
  font-style: var(--theme-inter-bold-15-63-font-style);
  font-weight: var(--theme-inter-bold-15-63-font-weight);
  letter-spacing: var(--theme-inter-bold-15-63-letter-spacing);
  line-height: var(--theme-inter-bold-15-63-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  overflow: hidden;
}

.team-detail-squad .nationality-flags {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 2.55px 0px 4px;
  position: relative;
}

.team-detail-squad .nationality-flags-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.team-detail-squad .flagicon {
  /*background-image: url(../../../static/img/flagicon-1.png);*/
  background-position: 50% 50%;
  background-size: cover;
  border: 1px solid;
  border-color: var(--themegallery);
  height: 16px;
  position: relative;
  width: 20.66px;
  object-fit: contain;
}

.team-detail-squad .flagicon-no {
  /*background-image: url(../../../static/img/flagicon-1.png);*/
  background-position: 50% 50%;
  background-size: cover;
  height: 16px;
  position: relative;
  width: 20.66px;
  object-fit: contain;
}



.team-detail-squad .flagicon-2 {
  background-image: url(../../../static/img/flagicon-2.png);
  background-position: 50% 50%;
  background-size: cover;
  border: 1px solid;
  border-color: var(--themegallery);
  height: 16px;
  margin-left: -3.2e-14px;
  position: relative;
  width: 20.66px;
}

.team-detail-squad .no-image {
  align-self: stretch;
  width: 100%;
}

.team-detail-squad .image {
  width: 352px;
}

.popover {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  pointer-events: none;
}

.element-sperren-desktop {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  position: relative;
}

.element-sperren-desktop .instance-node-3 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-sperren-desktop .navigation-desktop-mobile {
  margin-bottom: -891.42px !important;
  margin-left: -22697.00px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .navigation-desktop-instance {
  margin-left: unset !important;
}

.element-sperren-desktop .navigation-desktop-mobile-instance {
  margin-bottom: -893.42px !important;
  margin-left: -22697.00px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .navigation-desktop-2 {
  margin-bottom: -891.42px !important;
  margin-left: -22761.00px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .navigation-desktop-3 {
  margin-bottom: -893.42px !important;
  margin-left: -22761.00px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .navigation-desktop-4 {
  margin-bottom: -891.42px !important;
  margin-left: -22825.00px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .navigation-desktop-5 {
  margin-bottom: -893.42px !important;
  margin-left: -22825.00px !important;
  margin-right: unset !important;
}

.element-sperren-desktop .page-control {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 0px 32px;
  position: relative;
  width: 100%;
}

.element-sperren-desktop .single-transfer {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.element-sperren-desktop .footer-4 {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-sperren-desktop .footer-5 {
  margin-left: -324px !important;
}

.element-sperren-desktop .page-content-framer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.element-sperren-desktop .page-control-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  padding: 0px 32px;
  position: relative;
  width: 900px;
}

.element-sperren-desktop .single-transfer-cell-instance {
  align-self: stretch !important;
  width: 100% !important;
}



.subtitle-2 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-75-upper-font-family);
  font-size: var(--theme-inter-bold-15-75-upper-font-size);
  font-style: var(--theme-inter-bold-15-75-upper-font-style);
  font-weight: var(--theme-inter-bold-15-75-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-upper-line-height);
  align-self: stretch;
  position: relative;
}
.element-register {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.element-register .navigation-desktop-13 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-register .authentication-form {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--themewhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21px;
  max-width: 570px;
  padding: 30px;
  position: relative;
  width: 100vw;
}

.element-register .div-15 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element-register .authentication-state {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 24px;
  position: relative;
  width: 100%;
}

.element-register .authentication-state-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 24px;
  justify-content: center;
  padding: 11px 253px;
  position: relative;
}

.element-register .authentication-state-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 2px;
  margin-left: -130.00px;
  margin-right: -98.00px;
  padding: 0px 4px;
  position: relative;
  width: 32px;
}

.element-register .authentication-state-3 {
  background-color: var(--themeswamp);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--themeswamp);
  border-top-style: solid;
  border-top-width: 1px;
  height: 2px;
  position: relative;
  width: 24px;
}

.element-register .authentication-state-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 1px;
  margin-left: -98.00px;
  margin-right: -130.00px;
  padding: 0px 4px;
  position: relative;
  width: 32px;
}

.element-register .authentication-state-5 {
  background-color: var(--themeswamp);
  height: 1px;
  opacity: 0.4;
  position: relative;
  width: 24px;
}

.element-register .div-wrapper-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 00px 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-register .authentication {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  max-width: 570px;
  padding: 32px 0px;
  position: relative;
}

.element-register .authentication-2 {
  align-self: stretch;
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 38px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 48px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.element-register .authentication-3 {
  align-self: stretch;
  color: var(--themenevada);
  font-family: "Inter", Helvetica;
  font-size: 15.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
}

.element-register .authentication-state-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  justify-content: center;
  min-height: 48px;
  position: relative;
  width: 100%;
}

.element-register .authentication-state-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  max-width: 570px;
  position: relative;
}

.element-register .authentication-state-8 {
  align-items: flex-start;
  align-self: stretch;
  border: 2px solid;
  border-color: var(--themesilver-sand);
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 2px;
  position: relative;
  width: 100%;
}

.element-register .title-wrapper {
  align-items: flex-start;
  background-color: var(--themesilver-sand);
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  opacity: 0.4;
  position: relative;
}

.element-register .title-5 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 11.7px;
  font-weight: 700;
  letter-spacing: 1.00px;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 64px;
}

.element-register .authentication-state-9 {
  align-items: flex-start;
  background-color: var(--themewhite);
  box-shadow: 0px 1px 8px #000e141a;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}

.element-register .title-6 {
  color: var(--themeswamp);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 11.7px;
  font-weight: 700;
  letter-spacing: 1.00px;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.element-register .authentication-form-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 16px 0px 0px;
  position: relative;
  width: 100%;
}

.element-register .authentication-form-3 {
  color: var(--themenevada);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 23px;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-left: -88.50px;
  margin-right: -88.50px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 487px;
}

.element-register .authentication-form-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.element-register .authentication-form-4 {
  align-items: center;
  /*align-self: stretch;*/
  background-color: var(--themewild-sand);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  height: 48px;
  overflow: hidden;
  padding: 0px 16px;
  position: relative;
  width: 100%;
}

.element-register .authentication-form-5 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  min-height: 30px;
  padding: 2px 0px;
  position: relative;
}

.element-register .authentication-form-6 {
  color: var(--themeblack-60);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.29px;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-register .authentication-form-7 {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  margin-top: 8px;
  position: relative;
  height: auto;
  background-color: #00000000;
  border-color: #00000000;
}

.element-register .authentication-form-8 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 1px;
  height: 30px;
  padding: 2px 0px;
  position: relative;
}

.element-register .authentication-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.element-register .authentication-form-9 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 9.7px;
  font-weight: 400;
  letter-spacing: 0.10px;
  line-height: 16px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.element-register .authentication-form-10 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--themeswamp);
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  min-height: 48px;
  padding: 8.5px 16px 9px;
  position: relative;
  width: 100%;
}

.element-register .authentication-form-11 {
  color: var(--themewhite);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 11.4px;
  font-weight: 700;
  letter-spacing: 1.00px;
  line-height: 30px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.element-register .authentication-form-12 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--themeswamp);
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  min-height: 48px;
  padding: 8.5px 17px 9px;
  position: relative;
}

.element-register .authentication-form-13 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 11.7px;
  font-weight: 700;
  letter-spacing: 1.00px;
  line-height: 30px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-register .footer-11 {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-register .footer-12 {
  margin-left: -324px !important;
}

.element-register .frame-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 60px 16px;
  position: relative;
  width: 100%;
}

.element-register .frame-5 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.element-register .authentication-form-14 {
  align-items: flex-start;
  background-color: var(--themewhite);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 21px;
  max-width: 570px;
  padding: 20px 0px;
  position: relative;
}

.element-register .authentication-state-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 2px;
  margin-left: -40.00px;
  margin-right: -8.00px;
  padding: 0px 4px;
  position: relative;
  width: 32px;
}

.element-register .authentication-state-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 1px;
  margin-left: -8.00px;
  margin-right: -40.00px;
  padding: 0px 4px;
  position: relative;
  width: 32px;
}

.element-register .authentication-form-15 {
  color: var(--themenevada);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 23px;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: 487px;
}


.modal-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80vw;
  height: 80vh;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.modal-title {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.modal-text {
  font-size: 1rem;
  white-space: pre-wrap;
  line-height: 1.5;
}

.close-button {
  margin-top: 20px;
  padding: 10px 20px;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

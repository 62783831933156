.element-transfer {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 1000px;
  justify-content: space-between;
  position: relative;
}

.element-transfer .navigation-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-transfer .transfer-confirmation {
  flex: 0 0 auto !important;
}

.element-transfer .footer-4 {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

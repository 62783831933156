.element-table-mobile {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  position: relative;
}

.element-table-mobile .instance-node-5 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-table-mobile .navigation-desktop-6 {
  margin-bottom: -906.42px !important;
  margin-left: -617.00px !important;
  margin-right: unset !important;
}

.element-table-mobile .navigation-desktop-7 {
  margin-left: unset !important;
}

.element-table-mobile .navigation-desktop-8 {
  margin-bottom: -908.42px !important;
  margin-left: -617.00px !important;
  margin-right: unset !important;
}

.element-table-mobile .navigation-desktop-9 {
  margin-bottom: -906.42px !important;
  margin-left: -681.00px !important;
  margin-right: unset !important;
}

.element-table-mobile .navigation-desktop-10 {
  margin-bottom: -908.42px !important;
  margin-left: -681.00px !important;
  margin-right: unset !important;
}

.element-table-mobile .navigation-desktop-11 {
  margin-bottom: -906.42px !important;
  margin-left: -745.00px !important;
  margin-right: unset !important;
}

.element-table-mobile .navigation-desktop-12 {
  margin-bottom: -908.42px !important;
  margin-left: -745.00px !important;
  margin-right: unset !important;
}

.element-table-mobile .league-table {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element-table-mobile .league-table-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 5px;
  position: relative;
  width: 100%;
}

.element-table-mobile .league-table-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 1140px;
  position: relative;
  width: 100%;
}

.element-table-mobile .container-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element-table-mobile .div-wrapper-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--themewhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element-table-mobile .table {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-table-mobile .row-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--themeheavy-metal);
  display: flex;
  flex-direction: column;
  height: 21px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-table-mobile .row {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 21px;
  justify-content: flex-end;
  padding: 0px 0px 0px 00px;
  position: relative;
  width: 100%;
}

.element-table-mobile .cell {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 12px 1px 1px;
  position: relative;
  width: 23.66px;
}

.element-table-mobile .text-wrapper-25 {
  color: var(--themegallery);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -0.34px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .cell-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 8px 1px 1px;
  position: relative;
  width: 20.83px;
}

.element-table-mobile .text-wrapper-26 {
  color: var(--themegallery);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -0.17px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .cell-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 8px 1px 1px;
  position: relative;
  width: 17.53px;
}

.element-table-mobile .text-wrapper-27 {
  color: var(--themegallery);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -0.47px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .cell-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 11.99px 1px 1.94px;
  position: relative;
  width: 20.45px;
}

.element-table-mobile .text-wrapper-28 {
  color: var(--themegallery);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -0.48px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .cell-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 24.42px 1px 13.42px;
  position: relative;
  width: 45.84px;
}

.element-table-mobile .text-wrapper-29 {
  color: var(--themegallery);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .cell-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 16px 1px 8.44px;
  position: relative;
  width: 31.89px;
}

.element-table-mobile .text-wrapper-30 {
  color: var(--themegallery);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -0.55px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .body {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-table-mobile .row-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 40px;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.element-table-mobile .data {
  background-color: var(--themela-palma);
  height: 40px;
  position: relative;
  width: 3px;
}

.element-table-mobile .data-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 12px;
  position: relative;
  width: 30.89px;
}

.element-table-mobile .text-wrapper-31 {
  color: var(--themeouter-space);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-3 {
  height: 20px;
  object-fit: contain;
  position: relative;
  width: 20px;
}

.element-table-mobile .container-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  /*padding: 10px 1px 10px;*/
  padding: 12px;
  position: relative;
  width: 140px;
}

.element-table-mobile .container-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -0.86px;
  position: relative;
  width: 100%;
}

.element-table-mobile .text-wrapper-32 {
  align-self: stretch;
  color: black;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;

  /* Added properties for text truncation */
  white-space: nowrap;       /* Prevent text from wrapping */
  overflow: hidden;          /* Hide overflowing text */
  text-overflow: ellipsis;   /* Show '...' for truncated text */
}

.element-table-mobile .data-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 13.85px 12.01px 9.01px 27.2px;
  position: relative;
  width: 46.66px;
}

.element-table-mobile .text-wrapper-33 {
  color: var(--themeouter-space);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-right: -0.55px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 13.85px 8px 9.01px 5.38px;
  position: relative;
  width: 20.83px;
}

.element-table-mobile .text-wrapper-34 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-right: -0.55px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 13.85px 8px 9.01px 2.08px;
  position: relative;
  width: 17.53px;
}

.element-table-mobile .data-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 13.85px 12px 9.01px 1px;
  position: relative;
  width: 20.45px;
}

.element-table-mobile .data-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 13.85px 15.72px 9.01px 4.71px;
  position: relative;
  width: 45.84px;
}

.element-table-mobile .text-wrapper-35 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 13.85px 16px 9.01px 1px;
  position: relative;
  width: 31.89px;
}

.element-table-mobile .text-wrapper-36 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .mask-group-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 10.92px 4px 13.08px 1px;
  position: relative;
  width: 21px;
}

.element-table-mobile .mask-group {
  height: 20px;
  position: relative;
  width: 20px;
}

.element-table-mobile .data-10 {
  height: 40px;
  object-fit: cover;
  position: relative;
  width: 44px;
}

.element-table-mobile .text-wrapper-37 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .data-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 13.85px 19.46px 9.01px 8.43px;
  position: relative;
  width: 45.84px;
}

.element-table-mobile .text-wrapper-38 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-right: -1.05px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .img-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 14.42px 4px 9.58px 1px;
  position: relative;
  width: 21px;
}

.element-table-mobile .text-wrapper-39 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-40 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 10.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .text-wrapper-41 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-right: -0.05px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-12 {
  background-color: var(--themescooter);
  height: 40px;
  position: relative;
  width: 3px;
}

.element-table-mobile .data-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 13.85px 12px 9.01px 1px;
  position: relative;
  width: 45.84px;
}

.element-table-mobile .data-14 {
  background-color: var(--themecornflower);
  height: 40px;
  position: relative;
  width: 3px;
}

.element-table-mobile .text-wrapper-42 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 10.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .text-wrapper-43 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-right: -0.05px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .row-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0px 0px 0px 3px;
  position: relative;
  width: 100%;
}

.element-table-mobile .data-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 13.85px 16px 9.01px 8.44px;
  position: relative;
  width: 31.89px;
}

.element-table-mobile .text-wrapper-44 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-right: -0.55px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-16 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 13.85px 4px 9.01px 12px;
  position: relative;
  width: 30.89px;
}

.element-table-mobile .text-wrapper-45 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 11.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .text-wrapper-46 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .data-17 {
  background-color: #eeac57;
  height: 40px;
  position: relative;
  width: 3px;
}

.element-table-mobile .data-18 {
  background-color: #d75452;
  height: 40px;
  position: relative;
  width: 3px;
}

.element-table-mobile .text-wrapper-47 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .text-wrapper-48 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 11.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .table-legend {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--themewhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 40px;
  position: relative;
  width: 100%;
}

.element-table-mobile .list-2 {
  align-self: stretch;
  height: 120.56px;
  position: relative;
  width: 100%;
}

.element-table-mobile .legend-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 21px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 332px;
}

.element-table-mobile .legend {
  color: var(--themeouter-space);
  font-family: "Inter", Helvetica;
  font-size: 9.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .overlap {
  height: 19px;
  left: 0;
  position: absolute;
  top: 27px;
  width: 332px;
}

.element-table-mobile .item-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 0px 0.57px;
  position: absolute;
  top: 0;
  width: 177px;
}

.element-table-mobile .m-matches {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 9.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-49 {
  color: #34383c;
}

.element-table-mobile .text-wrapper-50 {
  color: #000e14;
}

.element-table-mobile .item-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 155px;
  padding: 0px 0px 0.57px;
  position: absolute;
  top: 0;
  width: 177px;
}

.element-table-mobile .goal-difference {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 10.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .overlap-group {
  height: 19px;
  left: 0;
  position: absolute;
  top: 53px;
  width: 332px;
}

.element-table-mobile .w-won {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 9.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .p-points {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .overlap-group-2 {
  height: 19px;
  left: 0;
  position: absolute;
  top: 78px;
  width: 332px;
}

.element-table-mobile .d-draw {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 9.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .g-goals {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .l-lost-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 0px 0.57px;
  position: absolute;
  top: 103px;
  width: 177px;
}

.element-table-mobile .l-lost {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 8.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.1px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .footer-7 {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-table-mobile .footer-8 {
  margin-left: -324px !important;
}

.element-table-mobile .content-frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-table-mobile .page-content-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1140px;
  padding: 0px 32px;
  position: relative;
}

.element-table-mobile .page-content-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 60px 0px;
  position: relative;
  width: 100%;
}

.element-table-mobile .league-table-content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: 1140px;
  position: relative;
  width: 100%;
}

.element-table-mobile .row-4 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  height: 21px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.element-table-mobile .cell-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 32px 1px 1px;
  position: relative;
  width: 79.3px;
}

.element-table-mobile .text-wrapper-51 {
  color: var(--themegallery);
  font-family: "Inter", Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -0.70px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .cell-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 16px 1px 1px;
  position: relative;
  width: 28.83px;
}

.element-table-mobile .cell-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 16px 1px 2.39px;
  position: relative;
  width: 26.92px;
}

.element-table-mobile .cell-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 31.99px 1px 4.41px;
  position: relative;
  width: 42.92px;
}

.element-table-mobile .cell-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 22.94px 1px 7.93px;
  position: relative;
  width: 60.78px;
}

.element-table-mobile .text-wrapper-52 {
  color: var(--themegallery);
  font-family: "Inter", Helvetica;
  font-size: 11.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -0.09px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .cell-12 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  justify-content: flex-end;
  padding: 0px 32px 1px 12.86px;
  position: relative;
  width: 62.77px;
}

.element-table-mobile .text-wrapper-53 {
  color: var(--themegallery);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: -0.09px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .cell-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 21px;
  padding: 0px 16px 1px 1px;
  position: relative;
  width: 51.33px;
}

.element-table-mobile .text-wrapper-54 {
  color: var(--themegallery);
  font-family: "Inter", Helvetica;
  font-size: 11.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .league-table-cells {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-table-mobile .row-5 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 56px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-table-mobile .data-19 {
  background-color: var(--themela-palma);
  height: 56px;
  position: relative;
  width: 3px;
}

.element-table-mobile .data-20 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 4px 13.99px 21.92px;
  position: relative;
  width: 35.84px;
}

.element-table-mobile .text-wrapper-55 {
  color: var(--themeouter-space);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-21 {
  height: 56px;
  object-fit: cover;
  position: relative;
  width: 65px;
}

.element-table-mobile .data-22 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 56px;
  padding: 13.58px 1px;
  position: relative;
}

.element-table-mobile .container-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -0.14px;
  padding: 5.57px 0px 0.41px;
  position: relative;
  width: 100%;
}

.element-table-mobile .text-wrapper-56 {
  align-self: stretch;
  color: black;
  font-family: "Inter", Helvetica;
  font-size: 15.2px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .data-23 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 32.01px 13.99px 62.38px;
  position: relative;
  width: 104.31px;
}

.element-table-mobile .text-wrapper-57 {
  color: var(--themeouter-space);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.08px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-24 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 16px 13.99px 2.91px;
  position: relative;
  width: 28.83px;
}

.element-table-mobile .text-wrapper-58 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.08px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-25 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 16px 13.99px 1px;
  position: relative;
  width: 26.92px;
}

.element-table-mobile .data-26 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 32px 13.99px 1px;
  position: relative;
  width: 42.92px;
}

.element-table-mobile .text-wrapper-59 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -2.08px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-27 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 20.97px 13.99px 5.95px;
  position: relative;
  width: 60.78px;
}

.element-table-mobile .text-wrapper-60 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -2.14px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-28 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 32px 13.99px 1px;
  position: relative;
  width: 62.77px;
}

.element-table-mobile .text-wrapper-61 {
  color: var(--thememountain-meadow);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -0.23px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-29 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 16px 13.99px 15.49px;
  position: relative;
  width: 51.33px;
}

.element-table-mobile .text-wrapper-62 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-30 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 20px 15px;
  position: relative;
  width: 40px;
}

.element-table-mobile .data-31 {
  height: 56px;
  object-fit: cover;
  position: relative;
  width: 44px;
}

.element-table-mobile .text-wrapper-63 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 15.5px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .data-32 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 25.92px 13.99px 10.92px;
  position: relative;
  width: 60.78px;
}

.element-table-mobile .text-wrapper-64 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -2.06px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-33 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 32.01px 13.99px 10.92px;
  position: relative;
  width: 62.77px;
}

.element-table-mobile .text-wrapper-65 {
  color: var(--thememountain-meadow);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -0.16px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .row-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-table-mobile .data-34 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 23.58px 4px 16.42px 1px;
  position: relative;
  width: 21px;
}

.element-table-mobile .text-wrapper-66 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 15.4px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .text-wrapper-67 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-68 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -0.14px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-69 {
  color: var(--thememountain-meadow);
  font-family: "Inter", Helvetica;
  font-size: 15.8px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.16px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-70 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .text-wrapper-71 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.06px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-72 {
  color: var(--thememountain-meadow);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.16px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-35 {
  background-color: var(--themescooter);
  height: 56px;
  position: relative;
  width: 3px;
}

.element-table-mobile .data-36 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 16px 13.99px 1px;
  position: relative;
  width: 60.78px;
}

.element-table-mobile .text-wrapper-73 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-74 {
  color: var(--thememountain-meadow);
  font-family: "Inter", Helvetica;
  font-size: 15.4px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.16px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-37 {
  background-color: var(--themecornflower);
  height: 56px;
  position: relative;
  width: 3px;
}

.element-table-mobile .text-wrapper-75 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .text-wrapper-76 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -2.06px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .row-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 56px;
  justify-content: center;
  padding: 0px 0px 0px 3px;
  position: relative;
  width: 100%;
}

.element-table-mobile .text-wrapper-77 {
  color: var(--themeouter-space);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -0.08px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-78 {
  color: var(--thememountain-meadow);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-79 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 15.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .text-wrapper-80 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.14px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-38 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 16px 13.99px 25.41px;
  position: relative;
  width: 51.33px;
}

.element-table-mobile .text-wrapper-81 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.08px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-39 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 4px 13.99px 12px;
  position: relative;
  width: 35.84px;
}

.element-table-mobile .data-40 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 32.01px 13.99px 13.87px;
  position: relative;
  width: 62.77px;
}

.element-table-mobile .text-wrapper-82 {
  color: var(--themepomegranate);
  font-family: "Inter", Helvetica;
  font-size: 15.5px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.11px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-83 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -0.08px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-84 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 15.1px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .text-wrapper-85 {
  color: var(--themepomegranate);
  font-family: "Inter", Helvetica;
  font-size: 15.8px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.11px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .text-wrapper-86 {
  align-self: stretch;
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 14.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-top: -1.00px;
  position: relative;
}

.element-table-mobile .text-wrapper-87 {
  color: var(--themepomegranate);
  font-family: "Inter", Helvetica;
  font-size: 15.9px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -1.11px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .data-41 {
  background-color: #eeac57;
  height: 56px;
  position: relative;
  width: 3px;
}

.element-table-mobile .data-42 {
  background-color: #d75452;
  height: 56px;
  position: relative;
  width: 3px;
}

.element-table-mobile .data-43 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 19.15px 32.01px 13.99px 3.95px;
  position: relative;
  width: 62.77px;
}

.element-table-mobile .text-wrapper-88 {
  color: var(--themepomegranate);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.9px;
  margin-right: -0.19px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.element-table-mobile .list-margin-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--themewhite);
  display: flex;
  flex: 0 0 auto;
  padding: 24px;
  position: relative;
  width: 100%;
}

.element-table-mobile .list-margin {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0px 16px;
  position: relative;
  width: 502px;
}

.element-table-mobile .list-3 {
  align-self: stretch;
  height: 135.56px;
  position: relative;
  width: 100%;
}

.element-table-mobile .item-6 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 21px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 502px;
}

.element-table-mobile .m-matches-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 0px 0.57px;
  position: absolute;
  top: 42px;
  width: 251px;
}

.element-table-mobile .w-won-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 0px 0.57px;
  position: absolute;
  top: 68px;
  width: 251px;
}

.element-table-mobile .d-draw-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 0px 0.57px;
  position: absolute;
  top: 93px;
  width: 251px;
}

.element-table-mobile .item-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0px 0px 0.57px;
  position: absolute;
  top: 118px;
  width: 251px;
}

.element-table-mobile .goal-difference-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 251px;
  padding: 0px 0px 0.57px;
  position: absolute;
  top: 42px;
  width: 251px;
}

.element-table-mobile .p-points-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 251px;
  padding: 0px 0px 0.57px;
  position: absolute;
  top: 68px;
  width: 251px;
}

.element-table-mobile .g-goals-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 251px;
  padding: 0px 0px 0.57px;
  position: absolute;
  top: 93px;
  width: 251px;
}

.single-transfer-cell {
  background-color: var(--themewhite);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e9ebed;
  display: flex;
  padding: 9.14px 16px 10.16px;
  position: relative;
}

.single-transfer-cell .frame-2 {
  align-items: center;
  flex: 0 0 auto;
  position: relative;
}

.single-transfer-cell .left-container {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  position: relative;
}

.single-transfer-cell .image-4 {
  height: 35px;
  width: 35px;
  position: relative;
  overflow: hidden;
}

.single-transfer-cell .image-4 img {
  height: 100%;
  width: 100%;
  object-fit: contain; /* Ensures the image scales properly */
  display: block; /* Removes whitespace below the image */
}

.single-transfer-cell .name-2 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.single-transfer-cell .text-wrapper-11 {
  color: #000e14;
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
}

.single-transfer-cell .text-wrapper-12 {
  font-weight: 700;
}

.single-transfer-cell .left-container-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.single-transfer-cell .team-logo {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 24px;
}

.single-transfer-cell .image-5 {
  background-image: url(../../../static/img/image-4.png);
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  height: 24px;
  position: relative;
}

.single-transfer-cell .team-name {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  position: relative;
}

.single-transfer-cell .team-name-2 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 13.7px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.single-transfer-cell .left-container-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  max-width: 369.33px;
  position: relative;
}

.single-transfer-cell .name-3 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.single-transfer-cell.property-1-desktop {
  align-items: center;
  flex-wrap: wrap;
  gap: 25px 25px;
  height: 44px;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.single-transfer-cell.property-1-mobile {
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
;
}

.single-transfer-cell.property-1-desktop .frame-2 {
  display: inline-flex;
  gap: 25px;
  margin-bottom: -5.15px;
  margin-top: -5.15px;
}

.single-transfer-cell.property-1-mobile .frame-2 {
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  width: 100%;
}

.single-transfer-cell.property-1-desktop .left-container-2 {
  gap: 10px;
  max-width: 369.33px;
}

.single-transfer-cell.property-1-mobile .left-container-2 {
  gap: 25px;
}

.team-image {
  width: 100%;
}

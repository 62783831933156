.club-card {
  align-items: flex-start;
  background-color: var(--themewhite-02);
  box-shadow: 0px 2px 8px #000e140d;
  display: flex;
  flex-direction: column;
  height: 104px;
  justify-content: center;
  position: relative;
  max-width: 360px;
}

.club-card .club-card-content {
  align-items: center;
  align-self: stretch;
  background-color: var(--themewhite);
  display: flex;
  flex: 1;
  flex-grow: 1;
  padding: 20px;
  position: relative;
  width: 100%;
}

.club-card .image-wrapper {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: flex-end;
  padding: 5px;
  position: relative;
  width: 64px;
}

.club-card .image-6 {
  align-self: stretch;
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.club-card .club-card-content-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  padding: 0px 5px;
  position: relative;
}

.club-card .club-card-content-3 {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-47-font-family);
  font-size: var(--theme-inter-bold-15-47-font-size);
  font-style: var(--theme-inter-bold-15-47-font-style);
  font-weight: var(--theme-inter-bold-15-47-font-weight);
  letter-spacing: var(--theme-inter-bold-15-47-letter-spacing);
  line-height: var(--theme-inter-bold-15-47-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.club-card .club-card-content-4 {
  color: var(--themeoslo-gray);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

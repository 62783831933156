.action-button {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  max-width: 180px;
  position: relative;
  width: 180px;
}

.action-button .button-title-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: #000000;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 10px 16px;
  position: relative;
  cursor: pointer;
}

.action-button .button-title {
  color: var(--themewhite);
  flex: 1;
  font-family: var(--theme-inter-bold-11-58-upper-font-family);
  font-size: var(--theme-inter-bold-11-58-upper-font-size);
  font-style: var(--theme-inter-bold-11-58-upper-font-style);
  font-weight: var(--theme-inter-bold-11-58-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-11-58-upper-letter-spacing);
  line-height: var(--theme-inter-bold-11-58-upper-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 77.03px;
}

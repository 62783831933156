.fixture-data-cell {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  position: relative;
}

.fixture-data-cell .fixture-data {
  align-items: center;
  flex: 0 0 auto;
  position: relative;
}

.fixture-data-cell .home-team {
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 5px;
  position: relative;
}

@media (max-width: 500px) {
  .gameday-livescore-2 {

    }

  .justRight
  { flex-direction: row-reverse}
}

.fixture-data-cell .gameday-livescore {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  max-width: 1140px;
  position: relative;
}

.fixture-data-cell .gameday-livescore-2 {
  color: var(--themenevada);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  text-align: right;
  width: fit-content;
}

.fixture-data-cell .gameday-livescore-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 32px;
  justify-content: center;
  margin-left: -0.01px;
  position: relative;
  width: 32px;
}

.fixture-data-cell .gameday-livescore-3 {
  background-position: 50% 50%;
  background-size: cover;
  flex: 1;
  flex-grow: 1;
  position: relative;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.fixture-data-cell .schedule-container {
  align-items: center;
  border: 1px solid;
  border-color: #ebe5eb;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 4px 4px;
  position: relative;
  margin: 0px 10px;
}

.fixture-data-cell .time-string {
  color: #37003c;
  font-family: "Inter", Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: 39px;
}

.fixture-data-cell .away-team {
  align-items: center;
  flex-direction: column;
  padding: 0px 5px;
  position: relative;
}

.fixture-data-cell .gameday-livescore-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 32px;
  justify-content: center;
  position: relative;
  width: 32px;
}

.fixture-data-cell .gameday-livescore-5 {
  color: var(--themenevada);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: -0.01px;
  position: relative;
  width: fit-content;
}

.fixture-data-cell .staium-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9px;
  position: relative;
}

.stadium-wrapper {
  width: 200px;
  justify-items: center;
}
@media (max-width: 500px) {
  .stadium-wrapper {
    justify-items: center;
  }

  .element-gameday-mobile .livescore-2 {
    gap: 0px;
  }
}
  .justRight
  { flex-direction: row-reverse}


.fixture-data-cell .stadium-image {
  height: 13.75px;
  position: relative;
  width: 20px;
}

.fixture-data-cell .stadium-location {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.40px;
  line-height: 18px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fixture-data-cell .fixture-btn-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  cursor: pointer;
}

.fixture-data-cell .fixture-btn {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #ebe5eb;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}

.fixture-data-cell .btn-txt {
  color: #37003c;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.1px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fixture-data-cell.state-mobile {
  flex-direction: column;
  gap: 10px;
  padding: 8px 0px;
  margin: 10px;
  align-items: center;
}

.fixture-data-cell.state-desktop {
  justify-content: space-between;
  padding: 8px 32px;
  width: 100%;
  margin: 10px 0px;
  align-items: center;
}

.fixture-data-cell.state-mobile .fixture-data {
  display: flex;
  justify-content: space-between;
  /*margin-left: -10.49px;*/
  /*margin-right: -10.49px;*/
  /*width: 100%;*/
}

.fixture-data-cell.state-desktop .fixture-data {
  display: inline-flex;
  gap: 5px;
}

.fixture-data-cell.state-desktop .home-team {
  display: inline-flex;
  flex: 0 0 auto;
  width: 205px;
}

.fixture-data-cell.state-mobile .gameday-livescore-2 {
  margin-top: -1.00px;
  width: 100px;
  margin-right: 5px;
}

.fixture-data-cell.state-desktop .gameday-livescore-2 {
  white-space: nowrap;
}

.fixture-data-cell.state-mobile .away-team {
  display: flex;
}

.fixture-data-cell.state-desktop .away-team {
  display: inline-flex;
  flex: 0 0 auto;
}

.fixture-data-cell.state-mobile .gameday-livescore-5 {
  margin-top: -1.00px;
}

.fixture-data-cell.state-desktop .gameday-livescore-5 {
  text-align: right;
  white-space: nowrap;
}




.fixture-data-cell .score-container {
  align-items: center;
  border: 1px solid;
  border-color: #ebe5eb;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  align-content: center;
  padding: 4px 4px;
  position: relative;
  margin: 0px 10px;
}

.fixture-data-cell .score-string {
  color: #37003c;
  font-family: "Inter", Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  padding: 0px 10px;
  text-align: center;
}

.element-transfers-desktop {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
}

.element-transfers-desktop .instance-node-9 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-transfers-desktop .page-control-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 0px 32px;
  position: relative;
  width: 100%;
}

.element-transfers-desktop .single-transfer-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.element-transfers-desktop .footer-21 {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-transfers-desktop .footer-22 {
  margin-left: -324px !important;
}

.element-league-selection {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  position: relative;
}

.element-league-selection .instance-node {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;

}

.element-league-selection .page-header-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: #000000;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 32px;
  position: relative;
  width: 100%;
}

.element-league-selection .page-header-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  max-width: 1200px;
  padding: 0px 175px; /* Default padding */
  position: relative;
  width: 100%;
}

/* Media query for viewports under 800px */
@media (max-width: 800px) {
  .element-league-selection .page-header-2 {
    padding: 0px 0px; /* Adjusted padding */
  }
}

.element-league-selection .page-header-wrapper-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  max-width: 700px;
  position: relative;
  width: 100%;
}

.element-league-selection .page-header-wrapper-3 {
  align-self: stretch;
  color: var(--themewhite);
  font-family: var(--theme-inter-black-37-66-font-family);
  font-size: var(--theme-inter-black-37-66-font-size);
  font-style: var(--theme-inter-black-37-66-font-style);
  font-weight: var(--theme-inter-black-37-66-font-weight);
  letter-spacing: var(--theme-inter-black-37-66-letter-spacing);
  line-height: var(--theme-inter-black-37-66-line-height);
  margin-top: -1.00px;
  position: relative;
}

.element-league-selection .page-header-wrapper-4 {
  align-self: stretch;
  color: var(--themewhite-75);
  font-family: "Inter", Helvetica;
  font-size: 15.8px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
}

.element-league-selection .page-content {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 1200px;
  padding: 60px 0px;
  position: relative;
  width: 100%;
}

.element-league-selection .leagues-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  max-width: 1200px;
  position: relative;
  width: 100%;
}

.element-league-selection .instance-node-2 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  padding: 0px 16px !important;
  width: 100% !important;
}

.element-league-selection .league-cell-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); /* Ensures at least 260px per cell and up to 3 columns */
  gap: 18px; /* Single value for both row and column gaps */
  align-items: center;
  justify-items: stretch;
  max-width: 1200px;
  padding: 0px 16px 30px;
  width: 100%;
}

.element-league-selection .league-selection-cell {
  min-width: 260px;
  flex-grow: 1; /* Allows it to expand within grid cell */
  width: 100%; /* Ensures it takes up the full width of its grid cell */
}


.element-league-selection .footer-instance {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-league-selection .footer-2 {
  gap: 20px 10px !important;
}

.element-league-selection .footer-3 {
  margin-left: -324px !important;
}

.element-league-selection .div-14 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.element-league-selection .page-header-3 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  max-width: 1200px;
  padding: 0px 32px;
  position: relative;
  width: 836px;
}

.element-league-selection .page-content-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 60px 0px;
  position: relative;
  width: 100%;
}

.element-league-selection .leagues-wrapper-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  max-width: 1200px;
  padding: 0px 32px;
  position: relative;
  width: 900px;
}

.element-league-selection .league-cell-list-2 {
  display: grid; /* Use CSS Grid */
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); /* Grid with up to 3 columns */
  gap: 18px; /* Spacing between items */
  align-items: stretch; /* Ensure items stretch vertically */
  /*justify-items: center; !* Center the content horizontally *!*/
  max-width: 1200px; /* Restrict the width */
  padding: 0 0px 120px; /* Top/bottom padding with side spacing */
  width: 100%; /* Take full container width */
  box-sizing: border-box; /* Include padding in width */
}


.element-league-selection .sponsors-instance {
  align-items: center !important;
}

.element-league-selection .sponsors-3 {
  align-items: center !important;
  flex: 0 0 auto !important;
  justify-content: center !important;
  padding: 0px 16px !important;
  width: 900px !important;
}

.no-results-text {
  font-family: "Inter", Helvetica;
  font-weight: 700;
  font-size: 16px;
  color: black;
  text-align: left;
  margin: 20px 0;
}

.element-clubs-desktop {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.element-clubs-desktop .class {
  margin-bottom: -906.42px !important;
  margin-right: -780.00px !important;
}

.element-clubs-desktop .class-2 {
  margin-bottom: -908.42px !important;
  margin-right: -749.00px !important;
}

.element-clubs-desktop .class-3 {
  margin-bottom: -908.42px !important;
  margin-right: -813.00px !important;
}

.element-clubs-desktop .class-4 {
  margin-left: -746px !important;
}

.element-clubs-desktop .class-5 {
  margin-bottom: -906.42px !important;
  margin-right: -652.00px !important;
}

.element-clubs-desktop .class-6 {
  margin-left: -681px !important;
}

.element-clubs-desktop .class-7 {
  margin-left: -809px !important;
}

.element-clubs-desktop .class-8 {
  margin-bottom: -908.42px !important;
  margin-right: -685.00px !important;
}

.element-clubs-desktop .class-9 {
  margin-bottom: -906.42px !important;
  margin-right: -716.00px !important;
}

.element-clubs-desktop .instance-node-4 {
  /*align-self: stretch !important;*/
  flex: 0 0 auto !important;
  width: 100% !important;
  padding-top: 40px;
}

.element-clubs-desktop .page-content-3 {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.element-clubs-desktop .club-grid {
  align-items: center;
  /*align-self: stretch;*/
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 15px 32px;
  justify-content: flex-start;
  max-width: 1200px;
  position: relative;
  width: 100%;
  padding-bottom: 60px;
  padding-top: 20px;
}

.element-clubs-desktop .club-card-instance {
  flex: 1 !important;
  flex-grow: 1 !important;
  min-width: 289px !important;
  width: unset !important;
}

.element-clubs-desktop .page-content-4 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  max-width: 1200px;
  padding: 0px 20px;
  position: relative;
}

.element-clubs-desktop .class-10 {
  margin-left: -324px !important;
}

.element-clubs-desktop .footer-6 {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

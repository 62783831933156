.sponsors {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  position: relative;
  width: 1200px;
}

.sponsors .sponsors-container {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--themewhite);
  box-shadow: 0px 4px 20px #000e140d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  padding: 15px 20px;
  position: relative;
  width: 100%;
}

.sponsors .p {
  align-self: stretch;
  color: var(--themeouter-space);
  font-family: "Inter", Helvetica;
  font-size: 11.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.sponsors .span {
  color: #34383c;
  font-family: var(--theme-inter-regular-11-44-font-family);
  font-size: var(--theme-inter-regular-11-44-font-size);
  font-style: var(--theme-inter-regular-11-44-font-style);
  font-weight: var(--theme-inter-regular-11-44-font-weight);
  letter-spacing: var(--theme-inter-regular-11-44-letter-spacing);
  line-height: var(--theme-inter-regular-11-44-line-height);
}

.sponsors .text-wrapper-2 {
  font-family: var(--theme-inter-bold-11-44-font-family);
  font-size: var(--theme-inter-bold-11-44-font-size);
  font-style: var(--theme-inter-bold-11-44-font-style);
  font-weight: var(--theme-inter-bold-11-44-font-weight);
  letter-spacing: var(--theme-inter-bold-11-44-letter-spacing);
  line-height: var(--theme-inter-bold-11-44-line-height);
}

.sponsors .sponsors-container-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 64px 64px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.sponsors .sportsselection {
  height: 50px;
  object-fit: cover;
  position: relative;
}

.sponsors .image-2 {
  height: 50px;
  object-fit: contain;
  position: relative;
}

.sponsors .image-3 {
  height: 28px;
  object-fit: cover;
  position: relative;
  width: 85px;
}

.sponsors .image-4 {
  height: 80px;
  object-fit: contain;
  position: relative;
  width: 85px;
}

.sponsors .v-white {
  height: 47px;
  position: relative;
  width: 50px;
}

.sponsor {
  width: 100px;
  position: relative;
  height: 50px;
  object-fit: contain;
}
.sponsorc {
  width: 100px;
  position: relative;
  height: 50px;
  object-fit: cover;
}
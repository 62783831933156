.transfer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  max-width: 1200px;
  position: relative;
}

.transfer .player-bio {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 180px;
  max-width: 369.33px;
  position: relative;
  cursor: pointer;
}

.transfer .card {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 345.33px;
}

.transfer .background-shadow {
  align-items: center;
  align-self: stretch;
  background-color: var(--themewhite);
  box-shadow: 0px 2px 8px #000e140d;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 49.16px;
  justify-content: flex-end;
  min-height: 180px;
  position: relative;
  width: 100%;
}

.transfer .player-detail-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 151px;
  justify-content: center;
  left: 210px;
  position: absolute;
  top: 29px;
}

.transfer .player-detail {
  align-self: stretch;
  height: 147px;
  position: relative;
  width: 100%;
}

.transfer .card-data {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  padding: 1px 17px 9px;
  position: relative;
  width: 345.33px;
}

.transfer .card-data-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.transfer .logo-5 {
  background-image: url(../../../static/img/logo-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 50px;
  position: relative;
  width: 50px;
}

.transfer .content-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.transfer .div-3 {
  color: var(--themeblack);
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.transfer .text-wrapper-9 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
}

.transfer .text-wrapper-10 {
  font-weight: 700;
}

.transfer .subtitle-2 {
  color: var(--themeswamp);
  font-family: "Inter", Helvetica;
  font-size: 15.8px;
  font-weight: 400;
  letter-spacing: 1.00px;
  line-height: 25px;
  position: relative;
  text-align: center;
  max-width: 500px;
}

.transfer .text-wrapper-11 {
  font-weight: 700;
  letter-spacing: 0.16px;
  text-decoration: underline;
}

.transfer .text-wrapper-12 {
  font-weight: 500;
  letter-spacing: 0.16px;
}

.transfer .buttons {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.transfer .action-button-instance {
  align-self: unset !important;
  height: 48px !important;
}

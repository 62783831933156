.news-article {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e9ebed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0px 1px;
  position: relative;
}

.news-article .div-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--themewhite);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.news-article .news-article-img {
  align-self: stretch;
  background-color: #e9ebed;
  height: auto;
  position: relative;
  width: 100%;
  min-height: 320px;
  object-fit: cover;

}

.news-article .div-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 10px;
  position: relative;
  width: 100%;
  gap: 5px
}

.news-article .title-3 {
  align-self: stretch;
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-21-font-family);
  font-size: var(--theme-inter-bold-21-font-size);
  font-style: var(--theme-inter-bold-21-font-style);
  font-weight: var(--theme-inter-bold-21-font-weight);
  letter-spacing: var(--theme-inter-bold-21-letter-spacing);
  line-height: var(--theme-inter-bold-21-line-height);
  margin-top: -1.00px;
  position: relative;
  display: -webkit-box; /* Ensure truncation works */
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;

}

.news-article .subtitle-3 {
  align-self: stretch;
  color: var(--themenevada);
  font-family: "Inter", Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.9px;
  position: relative;
}

.news-article .subtitle-3 {
  align-self: stretch;
  color: var(--themenevada);
  font-family: "Inter", Helvetica;
  font-size: 15.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.9px;
  position: relative;
  display: -webkit-box; /* Ensure truncation works */
  -webkit-line-clamp: 3; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle-3 {
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Show max 3 lines */
  -webkit-box-orient: vertical;
}

.subtitle-3 strong {
  font-weight: bold;
}

.subtitle-3 u {
  text-decoration: underline;
}

.subtitle-3 em {
  font-style: italic;
}

.element-ligaordnung {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.element-ligaordnung .design-component-instance-node {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-ligaordnung .content-frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-ligaordnung .page-content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  max-width: 1200px;
  position: relative;
}

.element-ligaordnung .terms {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 0px 32px 60px;
  position: relative;
  width: 100%;
}

.element-ligaordnung .terms-header-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.element-ligaordnung .title-3 {
  align-self: stretch;
  color: var(--themeswamp);
  font-family: var(--theme-inter-black-37-03-font-family);
  font-size: var(--theme-inter-black-37-03-font-size);
  font-style: var(--theme-inter-black-37-03-font-style);
  font-weight: var(--theme-inter-black-37-03-font-weight);
  letter-spacing: var(--theme-inter-black-37-03-letter-spacing);
  line-height: var(--theme-inter-black-37-03-line-height);
  margin-top: -1.00px;
  position: relative;
}

.element-ligaordnung .div-wrapper-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element-ligaordnung .text-wrapper-13 {
  align-self: stretch;
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-15-75-font-family);
  font-size: var(--theme-inter-bold-15-75-font-size);
  font-style: var(--theme-inter-bold-15-75-font-style);
  font-weight: var(--theme-inter-bold-15-75-font-weight);
  letter-spacing: var(--theme-inter-bold-15-75-letter-spacing);
  line-height: var(--theme-inter-bold-15-75-line-height);
  margin-top: -1.00px;
  position: relative;
}

.element-ligaordnung .paragraph-wrapper-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 19px;
  position: relative;
  width: 100%;
}

.element-ligaordnung .paragraph-wrapper-3 {
  align-self: stretch;
  color: var(--themeouter-space);
  font-family: var(--theme-inter-bold-16-font-family);
  font-size: var(--theme-inter-bold-16-font-size);
  font-style: var(--theme-inter-bold-16-font-style);
  font-weight: var(--theme-inter-bold-16-font-weight);
  letter-spacing: var(--theme-inter-bold-16-letter-spacing);
  line-height: var(--theme-inter-bold-16-line-height);
  margin-top: -1.00px;
  position: relative;
}

.element-ligaordnung .paragraph-wrapper-4 {
  align-self: stretch;
  color: var(--themeouter-space);
  font-family: var(--theme-inter-regular-15-13-font-family);
  font-size: var(--theme-inter-regular-15-13-font-size);
  font-style: var(--theme-inter-regular-15-13-font-style);
  font-weight: var(--theme-inter-regular-15-13-font-weight);
  letter-spacing: var(--theme-inter-regular-15-13-letter-spacing);
  line-height: var(--theme-inter-regular-15-13-line-height);
  margin-top: -1.00px;
  position: relative;
}

.element-ligaordnung .footer-instance {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.table-of-contents {
  align-self: stretch;
  justify-items: left;
  border-radius: 8px;
  margin-bottom: 24px;
}

.toc-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.toc-link {
  background: none;
  border: none;
  text-align: left;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 0;
  text-decoration: underline;
}

.paragraph-section {
  margin-top: 40px;
  padding-top: 10px;
  width: 100%;
}

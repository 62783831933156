.element-mobilenav {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 390px;
}

.element-mobilenav .div-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 390px;
}

.element-mobilenav .nav-row-wrapper-16 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #000000;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 15px 18px 10px;
  position: relative;
  width: 100%;
}

.element-mobilenav .nav-row-wrapper-17 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 30.92px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.element-mobilenav .filler {
  height: 14px;
  position: relative;
  width: 20px;
}

.element-mobilenav .close {
  position: relative;
  width: 24px;
}

.element-mobilenav .mobile-menu-items {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px #000e140d;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  padding: 19px 32px;
  position: relative;
  width: 100%;
}

.element-mobilenav .mobile-menu-item {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-mobilenav .mobile-menu-item-2 {
  color: #888c90;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16.6px;
  font-weight: 400;
  height: 27px;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
}

.element-mobilenav .mobile-menu-item-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  margin-bottom: -5.92px;
  position: relative;
  width: 100%;
}

.element-mobilenav .mobile-menu-login {
  align-items: center;
  align-self: stretch;
  background-color: #000000;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 20px 32px;
  position: relative;
  width: 100%;
}

.element-mobilenav .text-wrapper-24 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 13.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-mobilenav .dfl-simple-icon-user {
  height: 24px;
  position: relative;
  width: 24px;
}

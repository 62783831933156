.navigation-desktop {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.navigation-desktop .main-navigation {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--themewhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.navigation-desktop .league-rows {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e9ebed;
  display: flex;
  flex-direction: column;
  height: 36px;
  justify-content: center;
  overflow-x: scroll;
  padding: 0px 0px 10px;
  position: relative;
  width: 100%;
}

.navigation-desktop .leauge-row-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 15px;
  max-width: 1440px;
  padding: 5.08px 18px;
  position: relative;
  width: 100%;
}

.navigation-desktop .league-row-item {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  position: relative;
}

.navigation-desktop .link {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 24px;
  justify-content: center;
  position: relative;
}

.league-row-item.active .link {
  background-color: black; /* Example: Set background */
  color: white; /* Example: Text color */
  padding-left: 2px;
  padding-right: 2px;
}


.navigation-desktop .div {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3px;
  position: relative;
}

.navigation-desktop .content {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 49px;
}

.navigation-desktop .img {
  height: 13px;
  position: relative;
  width: 16px;
}

.navigation-desktop .text-wrapper {
  font-family: var(--theme-inter-bold-12-font-family);
  font-size: var(--theme-inter-bold-12-font-size);
  font-style: var(--theme-inter-bold-12-font-style);
  font-weight: var(--theme-inter-bold-12-font-weight);
  letter-spacing: var(--theme-inter-bold-12-letter-spacing);
  line-height: var(--theme-inter-bold-12-line-height);
  margin-top: -1.00px;
  position: relative;
  width: fit-content;
}

.navigation-desktop .league-row-item-2 {
  align-self: stretch;
  height: 1.5px;
  background-color: black;
  width: 100%;
  position: relative;
}

.navigation-desktop .link-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  margin-right: -15.00px;
  position: relative;
}

.navigation-desktop .league-row-item-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  margin-right: -79.00px;
  position: relative;
}

.navigation-desktop .league-row-item-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  margin-right: -143.00px;
  position: relative;
}

.navigation-desktop .league-row-item-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -8.16px;
  margin-right: -207.00px;
  position: relative;
}

.navigation-desktop .nav-row-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 15px 18px 10px;
  position: relative;
  width: 100%;
}

.navigation-desktop .nav-row-wrapper-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 30.92px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.navigation-desktop .nav-row-wrapper-3 {
  height: 44.03px;
  margin-bottom: -6.56px;
  margin-top: -6.56px;
  object-fit: cover;
  position: relative;
  width: 120px;
}

.navigation-desktop .mobile-burger-menu {
  position: relative;
  width: 24px;
}
/* Drawer Overlay */
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: flex-end;
}

/* Drawer Content */
.drawer-content {
  background-color: #fff;
  width: 300px;
  height: 100%;
  position: relative;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.league-row-wrapper {
  padding-left: 12px;
  display: flex;
  gap: 15px;
}
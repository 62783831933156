.element-homepage-desktop {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  position: relative;
}

.element-homepage-desktop .design-component-instance-node {
  /*align-self: stretch !important;*/
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-homepage-desktop .content-frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-homepage-desktop .page-content-22 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  max-width: 1200px;
  padding: 0px 32px;
  position: relative;
}

.element-homepage-desktop .div-17 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 1200px;
  position: relative;
  width: 100%;
  scroll-behavior: auto;
  -ms-scroll-rails: railed;
  padding-left: 12px;

}

.element-homepage-desktop .matchup-row-list {
  display: flex;
  gap: 12px; /* Space between items */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping */
  padding-bottom: 10px; /* Add padding for spacing */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
  width: -webkit-fill-available;
}

.element-homepage-desktop .matchup-row-list::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.element-homepage-desktop .matchup-cell-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  height: unset !important;
}

.element-homepage-desktop .matchup-cell-2 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  height: unset !important;
  margin-left: -1px !important;
}

.element-homepage-desktop .hero-instance {
  /*align-self: stretch !important;*/
  width: 100% !important;
}

.element-homepage-desktop .club-carousel-header {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 20px;
  position: relative;
  width: 100%;
}

.element-homepage-desktop .club-carousel-title {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-15-33-upper-font-family);
  font-size: var(--theme-inter-bold-15-33-upper-font-size);
  font-style: var(--theme-inter-bold-15-33-upper-font-style);
  font-weight: var(--theme-inter-bold-15-33-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-15-33-upper-letter-spacing);
  line-height: var(--theme-inter-bold-15-33-upper-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-homepage-desktop .club-carousel-action {
  color: var(--themeswamp);
  font-family: var(--theme-inter-bold-10-97-upper-font-family);
  font-size: var(--theme-inter-bold-10-97-upper-font-size);
  font-style: var(--theme-inter-bold-10-97-upper-font-style);
  font-weight: var(--theme-inter-bold-10-97-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-10-97-upper-letter-spacing);
  line-height: var(--theme-inter-bold-10-97-upper-line-height);
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-homepage-desktop .club-carousel-list {
  align-items: center;
  display: flex;
  gap: 13px;
  height: 146px;
  overflow-x: auto; /* Enable horizontal scroll */
  white-space: nowrap; /* Prevent wrapping */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
  width: -webkit-fill-available;
}

.element-homepage-desktop .club-carousel-list::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, Opera */
}

.element-homepage-desktop .club-cell-instance {
  margin-right: -2971.00px !important;
  margin-top: -275.92px !important;
}

.element-homepage-desktop .action-cell-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 equal columns */
  gap: 22px; /* Space between grid items */
  justify-content: center;
  position: relative;
  width: 100%;
}

@media (max-width: 390px) {
  .element-homepage-desktop .action-cell-2 {
    grid-template-columns: 1fr; /* Single column on small screens */
  }
}

.element-homepage-desktop .action-cell-instance {
  min-width: 320px;
  width: 100%; /* Take full width of the grid column */
  flex-grow: 1;
}

.element-homepage-desktop .banner {
  align-items: center;
  /*align-self: stretch;*/
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  max-width: 1200px;
  position: relative;
  width: 100%;
}

.element-homepage-desktop .banner-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: #00000033;
  background-image: url(../../../static/img/banner-wrapper.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 313px;
  justify-content: center;
  padding: 32px;
  position: relative;
  width: 100%;
}

.element-homepage-desktop .banner-content {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 48px 375px;
  justify-content: space-around;
  padding: 0px 32px;
  position: relative;
  width: 100%;
}

.element-homepage-desktop .banner-content-left {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 34px;
  max-width: 500px;
  position: relative;
}

.element-homepage-desktop .title-14 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 49px;
  margin-top: -1.00px;
  position: relative;
}

.element-homepage-desktop .button-text-wrapper {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 41px;
  justify-content: center;
  position: relative;
  width: 169px;
}

.element-homepage-desktop .button-text {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element-homepage-desktop .news-7 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  max-width: 1200px;
  position: relative;
  width: 100%;
}

.element-homepage-desktop .news-container-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.element-homepage-desktop .page-content-23 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 0px 32px;
  position: relative;
  width: 100%;
}

.element-homepage-desktop .page-content-24 {
  color: var(--themeswamp);
  flex: 1;
  font-family: var(--theme-inter-bold-27-63-upper-font-family);
  font-size: var(--theme-inter-bold-27-63-upper-font-size);
  font-style: var(--theme-inter-bold-27-63-upper-font-style);
  font-weight: var(--theme-inter-bold-27-63-upper-font-weight);
  letter-spacing: var(--theme-inter-bold-27-63-upper-letter-spacing);
  line-height: var(--theme-inter-bold-27-63-upper-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.element-homepage-desktop .news-container-grid-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 20px 20px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.element-homepage-desktop .news-article-4 {
  max-width: 345px !important;
  width: 343px !important;
}

.element-homepage-desktop .action-button-10 {
  flex: 0 0 auto !important;
}

.element-homepage-desktop .action-button-11 {
  align-self: unset !important;
  height: 48px !important;
}

.element-homepage-desktop .footer-36 {
  /*align-self: stretch !important;*/
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element-homepage-desktop .news-container-grid-7 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
  width: 100%;
}

@media (max-width: 800px) {
  .element-homepage-desktop .news-container-grid-7 {
    grid-template-columns: repeat(1, 1fr); /* 2 columns on mobile */
  }
}

@media (min-width: 801px) {
  .element-homepage-desktop .news-container-grid-7 {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  }
}
